.block--only-text {
	padding: 80px 15px;
	background-color: #fff;
	box-shadow: 0 0 26px rgba(0, 0, 0, 0.17);
}

.block--only-text__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.block--only-text__text {
	flex-basis: 100%;
}

.block--only-text__text h1,
.block--only-text__text h2 {
	margin-top: 0;
	@apply --mi4;
	color: var(--dark-gold);
	font-size: 35px;
	text-transform: uppercase;
	text-align: left;
}

.block--only-text__text p {
	@apply --ad4i;
	color: var(--black);
	font-size: 16px;
}

.block--only-text__text b {
	@apply --ad7i;
	color: var(--dark-gold);
	font-size: 18px;
}






@media (width <= 767px) {
	.block--only-text {
		padding: 40px 15px;
	}	
}
