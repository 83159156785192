.block--photo-and-text {
	padding: 80px 15px;
	background-color: #fff;
	box-shadow: 0 0 26px rgba(0, 0, 0, 0.17);
}

.block--photo-and-text__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.block--photo-and-text__photo,
.block--photo-and-text__text {
	flex-basis: 45%;
}

.block--photo-and-text__photo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.block--photo-and-text__text {
}

.block--photo-and-text__text h1,
.block--photo-and-text__text h2 {
	margin-top: 0;
	@apply --mi4;
	color: var(--dark-gold);
	font-size: 35px;
	text-transform: uppercase;
}

.block--photo-and-text__text p {
	@apply --ad4i;
	color: var(--black);
	font-size: 16px;
}

.block--photo-and-text__text b {
	@apply --ad7i;
	color: var(--dark-gold);
	font-size: 18px;
}





@media (width <= 991px) {
	.block--photo-and-text__photo,
	.block--photo-and-text__text {
		flex-basis: 100%;
	}

	.block--photo-and-text__photo {
		margin-bottom: 40px;
	}
}





@media (width <= 767px) {
	.block--photo-and-text {
		padding: 40px 15px;
	}	
}
