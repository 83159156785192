.block--contacts__form {
	display: flex;
	justify-content: flex-end;
	flex-basis:50%;
	background-color: #FFF;
}

.block--contacts__form__handler {
	padding: 20px 40px;
}

@media (width <= 991px) {
	.block--contacts__form {
		flex-basis: 100%;
	}
}

@media (width >= 1200px) {
	.block--contacts__form__handler {
		width: 585px;
	}
}

@media (width >= 992px) and (width <= 1199px) {
	.block--contacts__form__handler {
		width: 485px;
	}
}

@media (width <= 991px) {
	.block--contacts__form__handler {
		width: 100%;
	}
}

.send-messages h2 {
	text-align: left;
	@apply --ad7;
	font-size: 24px;
	color: var(--dark-gold);
	text-transform: uppercase;
}

.send-messages__content {
	margin-top: 40px;
}

.send-messages .form-group {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
}

.send-messages .form-group input,
.send-messages .form-group textarea {
	background-color: white;
	border: 1px solid #dabc8e;
	border-radius: 2px;
	color: var(--black);
	@apply --ad4i;
	font-size: 22px;
}

.send-messages .form-group input {
	height: 50px;
	animation: order-autofill both .5s;
}

.send-messages .form-group textarea {
	min-height: 100px;
	animation: order-autofill both .5s;
}

@keyframes order-autofill {
	to {
		box-shadow: 0 0 0 99px white inset;
		-webkit-text-fill-color: var(--black);
	}
}

.send-messages .form-control:focus {
	outline: 2px solid var(--gold);
	outline-offset: -3px;
}

.send-messages .form-group textarea {
	resize: vertical;
}

.send-messages .order-btn {
	background-color: var(--red);
	border-radius: 2px;
	border: 1px solid var(--red);
	@apply --ad4i;
	font-size: 22px;
	color: #FFF;
	padding: 5px 15px;
	transition: color .3s, background-color .3s;
	margin-bottom: 30px;
}

.send-messages .order-btn:hover {
	background-color: #FFF;
	color: var(--red);
}

.send-messages .order-btn.disabled {
	cursor: no-drop;
}

.send-messages .order-btn:not(.disabled) {
	cursor: pointer;
	background-color: var(--dark-gold);
	border-color: var(--dark-gold);
}

.send-messages .order-btn:not(.disabled):hover {
	background-color: #FFF;
	color: var(--dark-gold);
}

@media (width <= 767px) {
	.send-messages h2 {
		font-size: 30px;
	}
}

@media (width <= 479px) {	
	.send-messages h2 {
		font-size: 20px;
		margin-left: 0;
		margin-bottom: 0;
	}

	.send-messages__content {
		margin-top: 20px;
	}

	.send-messages .order-btn {
		margin-bottom: 15px;
	}
}