.block--catalogue {
	margin: 50px 0;
}

.block--catalogue__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.block--catalogue__wrapper figure {
	flex-basis: calc(33% - 3px);
	margin-bottom: 10px;
	position: relative;
		background-color: transparent;
	@apply --boxShadow;
}

.block--catalogue__wrapper figure:hover {
	@apply --boxShadowHover;
}

.block--catalogue__wrapper figure::before {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	content: "";
	background-color: transparent;
	pointer-events: none;
}

.block--catalogue__wrapper figure:hover::before {
	background-color: rgba(255,255,255,.75);
}

.block--catalogue__wrapper figure::after {
	position: absolute;
	top: 5px;
	left: 5px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	content: "";
	border: 1px solid var(--gold);
	transition: transform .3s;
	pointer-events: none;
}

.block--catalogue__wrapper figure:hover::after {
	transform: scale(.95);
}

.block--catalogue__wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.block--catalogue__wrapper figcaption {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}

.block--catalogue__wrapper figcaption span {
	color: white;
	text-shadow: 0 1px 3px var(--black);
	@apply --mi4;
	font-size: 35px;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	@apply --transitionColor;
}

.block--catalogue__wrapper figure:hover span {
	color: var(--gold);
}





@media (width <= 991px) {
	.block--catalogue__wrapper figure {
		flex-basis: calc(50% - 5px);
	}
}





@media (width <= 641px) {
	.block--catalogue__wrapper {
		justify-content: center;
	}
	.block--catalogue__wrapper figure {
		flex-basis: 100%;
		max-width: 480px;
	}
}