@media (width <= 991px) {
	#mainMenu .for-desktop-only { display: none; }
	#mainMenu .for-mobile-only { display: block; }
} 

@media (width >= 992px) {
	#mainMenu .for-desktop-only { display: block; }
	#mainMenu .for-mobile-only { display: none; }
}


.site-header--bottom {
	background-color: var(--black);
}

.site-header--bottom__wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
}

#mainMenu .navbar-nav .for-desktop-only:hover .dropdown-menu {
	display: block;
}

.navbar-brand {
	height: auto;
	width: 300px;
	padding-left: 30px;
	@apply --transitionOpacityStart;
}

.navbar-brand:hover {
	@apply --transitionOpacityEnd;
}

.navbar-collapse {
	flex-grow: 1;
}

.navbar-nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.navbar-nav::before,
.navbar-nav::after {
	display: none;
}

.navbar-nav li a {
	@apply --ad4;
	font-family: Adonis;
	font-size: 18px;
	text-transform: uppercase;
	color: white;
	@apply --transitionColor;
}

.navbar-nav > .active > a,
.navbar-nav > li:hover > a {
	color: var(--gold);
}

.nav > li > a:hover,
.nav > li > a:focus {
	text-decoration: none;
	background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent;
	border-color: var(--black);
}

.navbar-nav > li > .dropdown-menu {
	background-color: var(--black);
	margin-top: -10px;
	padding-top: 20px;
	padding-bottom: 0;
	border-radius: 0;
	left: -10px;
}

.dropdown-menu > li {
	padding: 0 10px;
}

.dropdown-menu > li:not(:last-child) a {
	border-bottom: 1px solid var(--dark-gold);
}

.dropdown-menu > li > a {
	padding: 10px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	text-decoration: none;
	color: var(--dark-gold);
	background-color: transparent;
}





@media (width <= 991px) {

	.navbar-brand {
		/*width: 100%;*/
		width: auto;
		margin: 0 auto;
		padding-left: 15px;
	}

	.navbar-brand img {
		margin: 0 auto;
	}

	.navbar-toggle {
		position: absolute;
		right: 25px;
		top: 20px;
		margin: 0;
		@apply --transitionOpacityStart;
	}

	.navbar-toggle:hover {
		@apply --transitionOpacityEnd;
	}

	.navbar-toggle .icon-bar {
		background-color: #feeba5;
		width: 40px;
		height: 1px;
		transition: transform .3s ease-in;
	}

	.navbar-toggle .icon-bar + .icon-bar {
		margin-top: 9px;
	}

	.navbar-toggle .icon-bar:nth-child(1) {
		transform-origin: 0;
		transform: rotate(30deg);
	}

	.navbar-toggle .icon-bar:nth-child(2) {
		transform: rotateY(90deg);
		transform-origin: 50% 0 0;
	}

	.navbar-toggle .icon-bar:nth-child(3) {
		transform-origin: 0;
		transform: rotate(-30deg);
	}

	.navbar-toggle.collapsed .icon-bar {
		transform: rotate(0deg);
	}

	.navbar-collapse {
		box-shadow: none;
	}
	
	.navbar-nav {
		margin-left: 0;
		margin-right: 0;
		flex-wrap: wrap;
	}

	.navbar-nav li {
		width: 100%;
		text-align: center;		
	}

	.navbar-nav > li:not(:last-child) {
		position: relative;
	}

	.navbar-nav li:not(:last-child)::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		content: "";
		background-image: linear-gradient(to right, transparent 0%, var(--gold) 50%, transparent 100%);
	}

	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 10px 0;
	}

	.dropdown-menu li {
		background-image: linear-gradient(to right, transparent 0%, var(--dark-gold) 50%, transparent 100%);
	}

	.dropdown-menu li:hover {
		background-image: linear-gradient(to right, transparent 0%, var(--gold) 50%, transparent 100%);	
	}

	.dropdown-menu li:not(:last-child) {
		position: relative;
	}

	.dropdown-menu li:not(:last-child)::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		content: "";
		background-image: linear-gradient(to right, transparent 0%, var(--gold) 50%, transparent 100%);
	}

	.dropdown-menu > li > a,
	.dropdown-menu > li:hover > a {
		color: var(--black);
	}

	.dropdown-menu > li:not(:last-child) a {
		border-bottom: none;
	}

	.navbar-nav > li > .dropdown-menu {
		margin-top: 10px;
		padding-top: 0;
	}

}





@media (width <= 767px) {
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav li:not(:last-child)::after {
		background-color: var(--gold);
		background-image: none;
	}

	.dropdown-menu li {
		background-color: var(--dark-gold);
		background-image: none;		
	}

	.dropdown-menu li:hover {
		background-color: var(--gold);
		background-image: none;	
	}
}





@media (width <= 479px) {
	.navbar-toggle {
		right: 0;
	}
}