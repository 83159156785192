.block--contacts__title {
	padding-bottom: 100px;
	padding-top: 50px;
	/*background-color: rgba(255,255,255,.7);*/
}

.block--contacts__title h1 {
	color: var(--dark-gold);
	@apply --ad7;
	font-size: 40px;
	text-transform: uppercase;
	text-align: center;
}

.block--contacts__title__wrapper {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.block--contacts__title__item {
	text-align: center;
	flex-basis: 30%;
}

.block--contacts__title__wrapper h2 {
	color: var(--dark-gold);
	@apply --ad7;
	font-size: 30px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 15px;
}

.block--contacts__title__wrapper p,
.block--contacts__title__wrapper a {
	color: var(--black);
	@apply --ad4i;
	font-size: 20px;

}

.block--contacts__title__wrapper span,
.block--contacts__title__wrapper a {
	display: block;
	margin-bottom: 5px;
}

.block--contacts__title__wrapper a {
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.block--contacts__title__wrapper a:hover {
	@apply --transitionOpacityEnd;
}




@media (width <= 767px) {
	.block--contacts__title__item {
		flex-basis: 100%;
		padding: 0 20px;
		text-align: center;
		margin-bottom: 20px;
	}

	.block--contacts__title__item h2 {
		text-align: center;
		margin-bottom: 10px;
	}
}