.block--contacts__map {
	flex-basis:50%;
	overflow: hidden;
	background-color: #fff;
}

.block--contacts__map iframe {
	pointer-events: none;
}

.block--contacts__map iframe {
	width: 100%;
}

@media (width <= 991px) {
	.block--contacts__map {
		flex-basis: 100%;
	}
}