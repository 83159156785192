.site-header--top {
	background-color: #fff;
}

.site-header--top__wrapper {
	padding: 10px 0;
}

.site-header--top__wrapper a {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	@apply --transitionOpacityStart;
}

.site-header--top__wrapper a:hover {
	@apply --transitionOpacityEnd;
}

.site-header--top__wrapper a img {
	height: auto;
	max-width: 100%;
}