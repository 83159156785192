@media (width >= 489px) {}
@media (width >= 768px) {}
@media (width >= 992px) {}
@media (width >= 1200px) {}
@media (width <= 1199px) {}
@media (width <= 991px) {}
@media (width <= 767px) {}
@media (width <= 479px) {}



html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;
}

main {
	flex: 1 0 auto;
}

main {
	background-image: url(../img/main-pattern_20.png);
	background-repeat: repeat;
}

.site-main {
	/*margin-top: 70px;*/
}

.site-main.space-plus {
	margin-top: 80px;
}

footer {
  flex: 0 0 auto;
}

h1,
h2 {
  margin-top: 40px;
  color: var(--dark-gold);
  @apply --ad7;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
}