.carousel-fade {
	padding-bottom: 75px;
	background-color: var(--black);
	background-image: url(../img/main-pattern_20.png);
	background-repeat: repeat;
}
.carousel-fade .carousel-inner {
	/*background-color: var(--gold);*/
}
.carousel-fade .carousel-inner .item {
	transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	left: 0;
	transform: translate3d(0, 0, 0);
}





@media (width <= 767px) {
	.carousel-fade {
		padding-bottom: 25px;
	}
}
