.site-footer {
	background-color: var(--black);
	background-image: url(../img/main-pattern_20.png);
	background-repeat: repeat;
}

.site-footer__wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 30px 0;
}

.site-footer__copy,
.site-footer__zwebra {
	flex-basis: 230px;
	margin-bottom: 0;
	font-size: 14px;
}

.site-footer__copy {
	@apply --op4i;
	color: #a89379;
}

.site-footer__social {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: calc(100% - 460px);
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.site-footer__social li {
	margin: 0 7.5px;
}

.site-footer__social li a {
	display: inline-block;
	width: 35px;
	height: 35px;
	background-color: #FFF;
	border-radius: 50%;
	text-decoration: none;
	vertical-align: bottom;
	position: relative;
}

.site-footer__social li a::after{
	position: absolute;
	top: 0;
	left: 0;
	width: 35px;
	height: 35px;
	content: "";
	filter: grayscale(0) contrast(1) invert(0);
	transition: filter .3s;
}

.site-footer__social li:nth-child(1) a::after {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAAA7VBMVEUAAADPpEatgTnOpEaugjm3iz3Cl0Gvgzr1zFb1zFa7kD60iTzKn0WvgzrwxlSvgzrmvFDSqEitgTnzyVXGm0PCl0HmvFDSqEjwxlTasEvKn0XiuE+0iTzpwFLCl0HiuE/asEvCl0HpwFK7jz/SqEi7jz+0iTzSqEi0iTyvgzq7jz/Cl0HwxlSvgzq7jz+7jz/Kn0W0iTzKn0WrfzjwxlTpwFKrfzivgzqrfzi7jz/Kn0X1zFavgzq0iTz1zFbasEvpwFK0iTyrfzjGmkP1zFbiuE/asEvSqEjpwFLKn0W7jz+0iTzwxlTCl0GvgzqrFgAbAAAARXRSTlMAARYFDgn5yKd4H/rX0MGdhFxVUU9FQf36+PXy8u/n5uTk4eHf0c/Hx8PAurizrKuqnpeQiYiGf35zcW1tTkU4NTExGRMrAfBTAAAAxklEQVQ4y+3HV67CMBRF0eM4IeXR2+u9N3rvPdhOMv/h4AgkpOABgMT6uPdsnJ0kXdM0Appt1TszAFSmjohBImFmoaeCIPg3gLb5baYQMbwQ4sOAcyfEl46pLFFFBClzzrvQXjn/MUhJxruDqELM9x8c49n3f5GXO1bAAVJjjFXyN4zFM3E5e1CYv7mue+nufBKoZK5Xe09jKKWXW7fheSxChTbXoftiOnwvE6iQ3N+V5yUJbXhSEmqLXN8eEVDbsiwbZ0dnA+B9IS5v+V9WAAAAAElFTkSuQmCC');
}

.site-footer__social li:nth-child(2) a::after {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAABHVBMVEUAAAD2zVerfzjyyVXds0zuxVTuxVTEmULGm0PSqEjMoUatgTjMoUbpwFLSqEjCl0HtxFPKn0XuxVTHm0PYrkrkuk+7jz/Gm0Pkuk/DmELetE3AlUG7jz/yyVW7jz/uxVSyhjurfzjuxVTpwFLkuk/etE3Yrkq5jT3yyVXGm0PyyVXkuk/etE3Yrkq2ijzGm0PyyVX2zVetgTirfzjpwFLht07bsUu7jz/kuk+yhjv2zVfetE2+kkDkuk/etE3etE3AlUHSqEjMoUbAlUGyhjvSqEiyhjurfzjAlUG7jz/AlUHpwFKugjnMoUa2ijzYrkrYrkrkuk/YrkrGm0PetE2rfzirfzjMoUaugjm2ijyyhjvAlUG7jz/yyVXkuk9NSawoAAAAWHRSTlMA28/WEiAbCfPc29vOzcpfOhwVEPz6+vjs6+jj4+De3d3d3Nzb29va1NLR0dHPzr6zsbGsnWtmPDU1LiUbGvr28+Ph2tfV09PPz87LycbErKBxXFRALCsibwzJcAAAAPBJREFUOMvtyrWSAlEQheHenTuMO+6+uLusuxsu7/8YzFBAEXRIEfEH3Sf44NSxG1afzjcJkUh1gJnKeJfwLowrmHmcbrtvG+1wGDOFybZPBpjCBWZe5lbOYNDpb/aaftS8zsx8itZTfJ6769kzZtJs0eNSrPXnYotsGjO8qH3cGtZieHEk8pjhZCLfkLXhHjSZw0wyb3zbG9b6tX+RfBIziRxpXXnr3W7De/mv5xKYiWf7UHcs3e6l4wf62ThmYhIBaNXepJoKQKQYZkJR1UbbdJrWzadGQ5gpLQIpiqIyGfOkAosSZjrls73KHTh1uFb5uSZsqQ1KFQAAAABJRU5ErkJggg==');
}

.site-footer__social li:nth-child(3) a::after {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAAAxlBMVEUAAADxyFXVqkn1zFerfzjEmULVqkm3izy/k0DtxFOzhzvMokXov1HzylXjuU+4jD2xhTq/k0DlvFDgtk66jj7bsEzEmUL2zVfEmUKrfzjVqkm1iTyxhTrPpUfJn0SugjmrfzixhTrVqkm/k0DzylbEmULgtk7bsEy6jj7EmULVqkmrfziugjnVqkm1iTy1iTy/k0DbsEy/k0CrfzjPpUfJn0SugjnEmUKxhTrzylbvxlTqwVLlvFDgtk7bsEy/k0C6jj61iTyCNKWeAAAANHRSTlMAKf729degIh308qeYlSkO+PX09PTz7+zd3NfOzMPCwLOwrJ+amZiRkIJsaWBcSzw7HRADRCGFfwAAAJBJREFUOMvtykcSgjAUANBIChFF7L333jWhw/0v5TjDNj8blrz1Q4XcvU4DQsgcPOfv3wwqzlhKWbcW0DGtNB1dbyZ42kmyR5AnvzfieMW5oz4b2hdCdCk9qM/0k6moz+SdAc6RLQ3DGDJ2QYBHJ4p2CFZrheFWd5pBUNWcUtn39cfzbN3BrrvWnR7GNirk7gdN9A+54KTthgAAAABJRU5ErkJggg==');
}

.site-footer__social li:nth-child(4) a::after {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAABzlBMVEUAAADNokb3zlf0ylb3zlfjuU/juU/InUStgTn0y1a/lEDSqEjetEyugTnEmUKziDu5jT7txFO7jz7nvVC6jj7iuE7InUTInUTInUTOo0bJnkTasEvzyVX3zleyhjrtxFPrwlL3zlflu1DrwlK3iz3xx1XnvlHasEu/lEDvxlS1ijzpwFK9kj/asEvpwFKwhDrrwlLrwlKwhTrpwFLvxlTnvlHxx1XvxlTgtk7lu1Dht06sgDjNokbNokawhDrpwFLcskzSqEjSqEjxx1XcskzSqEjgtk6rfzjlu1Dcskzgtk7cskzxx1XpwFLXrUrgtk6tgTnXrUq7jz+whDq3iz2ziDvlu1C/lEDxx1W7jz/csky7jz+ziDvtxFPEmULNoka/lEDNokaziDu3iz27jz/cskytgTnSqEi7jz+rfzjgtk7SqEjxx1Xlu1CziDvtxFPgtk73zlf0y1b3zlfcskzgtk7EmUL0y1blu1Dcskzgtk6whDrxx1Xxx1WwhDrXrUrxx1XEmULXrUrtxFPcskzEmUKwhDq3iz2rfzjxx1XEmULlu1CziDu/lECwhDrNokb3zlfSqEj0y1bXrUrNokbcskztxFPpwFLlu1Dgtk48IUsIAAAAkXRSTlMA+eD93XlUKQj30s3FqouJhHZ1XjgmJSIgGQXy8OLb2dHPzcW/u66gko+PioB9eHRvX1hTR0A/OjozLyP88fHu6urp5+fg3d3a2dfSzs7Ozc3IyMfGxcTCv7++vLu3rqyrqqahnJiXlo+PioqHhoOCgn99d3Z1dXBwbmlpZGFfW1dVUk9JREE9NjMvLS0lGBMI296xJgAAAUNJREFUOMvt0sV2wlAUheEESBqguLsULe5Wd3d3d3d391yg8raljG/aPgD/OuuMvuFGcv27RO3dPOcvxJlp8xMJREkQBKW5Kel84WgPT3geD6VRanlP3f6hlQleIUJZl1BsujXxJ/m/mA6huF88yJ9yzlEblvOZZRsTsnpt1IZ7juyMPCi43GsqIc/LJJPKpHK5VAYVr+NoKoWiaNHPQ4tHFRCzWM5ms0mSrKgms81CTLtVojhVJZOui2S2FojJT29dqQbq811xa1nV/nS6EWa+MLxVshbFogcL23ispgFiej4x3BhfDmEiyx4uejTWQUzBhzvQHHOE3D6LNyBylOph5l1wqZd4I4KgXeAbXtXpIKaPvnRUeR88tofDZnPkTN0EMbuAzgRMOh0AJgBqADZg49k0aBg0Go2ROYbGsP6G5EKQb1m9RMEz/16uAAAAAElFTkSuQmCC');
}

.site-footer__social li a:hover::after {
	filter: grayscale(1) contrast(20) invert(1);
}

.site-footer .site-footer__zwebra {
	text-decoration: none;
	@apply --op4;
	color: #a89379;
	@apply --transitionOpacityStart;
	display: flex;
	align-items: center;
}

.site-footer .site-footer__zwebra:hover {
	@apply --transitionOpacityEnd;
}

.site-footer__zwebra span {
	display: block;
}

.site-footer__zwebra > span:first-child span {
	text-align: right;	
	margin-right: 15px;
}

.site-footer__zwebra > span:first-child span:first-child {
	margin-bottom: -5px;
}

.site-footer__zwebra > span:last-child {
	width: 81px;
	height: 24px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAAAYCAMAAAC1BuGgAAAAbFBMVEUAAACok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3mok3l4FoV8AAAAJHRSTlMA7QjpV2cTNy5LO7F4D8ubJB9RKBbj28LVRtCqpJGLf3AaQLlDl1ebAAACp0lEQVQ4y3WVibKCMAxFm4JSZBUXkF38/3982VqeM5IZCW3DSU1uwbyA7WWMSdCfjEnRbTh0PFLDuVxvbQ7enuPFB7xx2PPdJmurTibG1OhqHMYAcSD2//GMVKt07oL3kaW7c9w0ac4L5wjgjQ6X7jhchFg4527uKUR75V0+iBWtM7lGiCsAPx0SfCiwg2eBroSOkg1CjIFNiJvsNAPZ0QldJoiBpi6BOAnqA60stpbAQkz/ExN4UrKZiWdjI+AJidZS+yH9nRFGw/EZ1+AnEQiQK9Fk7CiaCzF64lVaYiZJcuKVGo6JlSeOnuhoAkrer5bqxqlfUtUFr6/fxAc91HliB0hRxFDCg+hSaK6jaaCWLceM/9GZC8R8ZWLBepsZsUAUD7QvtiLSCiRQkLPcs0yJt2VZMPafeopSiXZgoZG1ENWTHBROqVqupW8molRPndVC7wp/lyDEqgXonPQ2QjHlodm51+Y90yrcpdWBWAtR7VaCt/YdUvZYNBiE3+JQRLXgr8AUVpoXiM4TzxtnfnhiN779Wa4o6mF5HVcWYaeYzWE9cb7xxMsny8q9M1M4ctEr6Twjx2UGcbNf+/G546jBnWDU6onNt3pYDlc9hbF/dKCmWBxxWUdMVBhv6yy+ggOFp6wO0eNNY2zLmUoVBDb1Y4JlrRb3iLjysWViOFf3CH3tWlGn02C1Eqxo9IjYEPHzvUenIbK3E/qaYXrizyKHI2JCxP6bGAfi0/LZ6KwHUkzi23X8pijgmzgDtGmTooMrL84mWKolWOFnr7feqeIDsZFvxqo6vPAR7Csy1uSkX44sEI0lSzSTNRwlREZMhmWzaTFOvKjGXxDRrO0CUZWqRH0RhLcZIbnoV91HZaKdiBFWvTZvh1zDW8DW1R5/o8e5MFaJ8Acn8R7SWE1DLQAAAABJRU5ErkJggg==');
}


















@media (width <= 1199px) {
	.site-footer__copy,
	.site-footer__social,
	.site-footer__zwebra {
		flex-basis: 100%;
		display: flex;
		justify-content: center;
	}

	.site-footer__social {
		margin: 20px 0;
	}
}