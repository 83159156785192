.block--pagination{
	margin-bottom: 80px;
}

.block--pagination ul {
	padding-left: 0;
	list-style: none;
	width: 100%;
	text-align: center;
}

.block--pagination li {
	display: inline-block;
	text-align: center;
	margin: 0 10px;
}


.block--pagination a {
	text-decoration: none;
	color: var(--dark-gold);
	background-color: transparent;
	border-radius: 50%;
	@apply --ad4i;
	font-size: 20px;
	text-transform: uppercase;
	padding: 5px 12px;
	transition: color .3s, background-color .3s;
}

.block--pagination li.active a,
.block--pagination li:hover a {
	background-color: var(--black);
}

.block--pagination .active:hover a {
	cursor: default;
}