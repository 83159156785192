.block--default {
	margin: 40px 0;
}

.block--default__wrapper {
	padding: 40px 0;
	background-color: #fff;
	box-shadow: 0 0 26px rgba(0, 0, 0, 0.17);
}

.block--default .block--photo-and-text {
	box-shadow: none;
	padding: 25px 15px;
}

.block--default .block--photo-and-text .container {
	width: 100%;
}

.block--default__wrapper > h1,
.block--default__wrapper > h2 {
	padding: 0 40px;
	@apply --mi4;
	color: var(--dark-gold);
	font-size: 35px;
	text-transform: uppercase;
}

.block--default__wrapper > h3 {
	padding: 0 40px;
	@apply --ad7;
	color: var(--dark-gold);
	font-size: 24px;
	text-transform: uppercase;
}

.block--default__wrapper > h4 {
	padding: 0 40px;
	@apply --ad7i;
	color: var(--black);
	font-size: 20px;
}

.block--default__wrapper > p {
	padding: 0 40px;
	@apply --ad4i;
	color: var(--black);
	font-size: 16px;
}

.block--default__wrapper > b {
	@apply --ad7i;
	color: var(--dark-gold);
	font-size: 18px;
}

.block--default__wrapper ul li,
.block--default__wrapper ol li {
	color: var(--black);
	@apply --ad4i;
	font-size: 16px;
}

.block--default__wrapper ul,
.block--default__wrapper ol {
	margin-bottom: 30px;
	padding-left: 65px;
	padding-right: 40px;
	margin-top: 20px;
}

.block--default__wrapper ul li,
.block--default__wrapper ol li {
	text-align: left;
	list-style: none;
	position: relative;
	margin-bottom: 20px;
}

.block--default__wrapper ul li::before {
	position: absolute;
	width: 14px;
	height: 25px;
	top: -1px;
	left: -25px;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAMAAAA2Re/AAAAAmVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAjHWqVAAAAMnRSTlMA9+2/WhHMukUvFfKzmpFqOR8K4dvUt6eCc2FLSScF6MSuo4uFY0E2JRsOk3p5ZT4pGgMSR44AAADTSURBVBjTPZDXloMwDERtAy703gKkbTbZbJ///7jY2DAv1h2NdY5EVk3pdfwguwSAKHlu6DElO5wChxdaEjLgzaEPaTK0sBiwRlcHjHu7OQShTm9cgZ2QWijy+ZGdOc8MvMcUqL7CtVMmQB17DNFohrZo5KLfIUK/kAxHmyJ3ih/yzxHP6wQOodcIBajoUw4kpbGXWw2t9k6cnr/+pGTfnf3NUQKI8Okop3RQD1wtlTXLtYfM4gTzq4OymCAw6172W4V/36iKDXEEvJk43eI2laZ4Abb/E0g/9+7lAAAAAElFTkSuQmCC');
}

.block--default__wrapper ol {
	counter-reset: my-counter;
}

.block--default__wrapper ol li {
	margin-left: 10px;
}

.block--default__wrapper ol li::before {
	position: absolute;
	top: -3px;
	left: -35px;
	content: counter(my-counter);
	counter-increment: my-counter;
	@apply --ad7i;
	color: white;
	background-color: var(--dark-gold);
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1;
}

.block--default__wrapper blockquote {
	background-image: linear-gradient(to top, #c68d3d 0%, #ab7427 100%);
	padding: 25px 40px;
	text-align: center;
	border-left: 0;
}

.block--default__wrapper blockquote p {
	@apply --ad4i;
	color: white;
	font-size: 32px;
	margin-bottom: 0;
	display: inline-block;
	position: relative;
}

.block--default__wrapper blockquote p::before,
.block--default__wrapper blockquote p::after {
	position: absolute;
	width: 14px;
	height: 25px;
	content: "";
	top: calc(50% - 12px);
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAMAAAA2Re/AAAAAllBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AJcWoAAAAMXRSTlMA9+2/WhHMurJFLxXymmo5Hwrh29S3qqSSgnNkYUtJJwXoxIuFQTYlGw6UkXp5PikaMuX5XwAAANJJREFUGNM9kNeWwjAMRO00l/ReSQLswrIN5v9/Djt2Mi/WHY11jkQ2zT/X6YscYgCi+LGj60jR4OJbbGlOyIAPix6EztDMoO9UqjphOtrVyQ9VeucCzgWdgSxd7kkTBImGT06B4jfcOnkMlNx1EE16aI1KrOodIvQrSXA2KXKjGMkrAF+2CQGYWiNkoKzvAiDOtb2OJZTqG7F6/HuzFH3Dvd2RDIjwbSmldJB3XA3lpZMqD4nBGfpXC2kwhq/XbY9bhc8/FNmOOAPuQqxGXndCF2+glhL7xTYpSAAAAABJRU5ErkJggg==');	
}

.block--default__wrapper blockquote p::before {
	left: -30px;
}

.block--default__wrapper blockquote p::after {
	right: -30px;
	transform: rotate(180deg);
}





@media (width <= 991px) {
	.block--default .block--photo-and-text {
		padding: 0 40px;
	}
}





@media (width <= 479px) {
	.block--default__wrapper {
		padding: 20px 0;
	}

	.block--default .block--photo-and-text,
	.block--default__wrapper h1,
	.block--default__wrapper h2,
	.block--default__wrapper h3,
	.block--default__wrapper h4,
	.block--default__wrapper p {
		padding: 0 20px;
	}

	.block--default__wrapper ul,
	.block--default__wrapper ol {
		padding-left: 45px;
		padding-right: 20px;
	}

	.block--default__wrapper blockquote p {
		line-height: 1;
	}
}